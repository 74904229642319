export const formatDataForForm = (data) => {
    const dataClone = { ...data, form_permissions: {} };

    if (data?.permissions?.length > 0) {
        data.permissions.forEach((permission) => {
            dataClone.form_permissions[permission.permission] =
                permission.value;
        });
    }

    return dataClone;
};

export const formatDataForApi = (data, isUpdate = false) => {
    const customerGroup = data?.customerGroup;
    const dataClone = { ...customerGroup, permissions: [] };

    // If emailSuffixes is a string, convert it to an array of strings (comma separated) and trim each value in the array of whitespace characters (if any)
    if(typeof dataClone?.emailSuffixes === 'string') dataClone.emailSuffixes = dataClone.emailSuffixes.split(',').map(suffix => suffix.trim())

    for (const [permission, value] of Object.entries(
        customerGroup?.form_permissions
    )) {
        let permissionId = permission;
        const permissionData = {
            value,
        };
        if (isUpdate) {
            const existingPermission = customerGroup?.permissions.find(
                (p) => p.permission === permission
            );
            if (!!existingPermission) {
                permissionData["@id"] = existingPermission["@id"];
            } else {
                permissionData.permission = permissionId;
            }
        } else {
            permissionData.permission = permissionId;
        }

        dataClone.permissions.push(permissionData);
    }

    if (!dataClone.type) {
        dataClone.type = null;
    }

    delete dataClone.form_permissions;
    delete dataClone["@id"];
    delete dataClone["@context"];
    delete dataClone["@type"];
    delete dataClone.id;
    delete dataClone.originId;

    return dataClone;
};
