import React from "react";
import { Field } from "formik";
import { Stack, Checkbox as ChakraCheckbox, Text } from "@chakra-ui/core";

export const Checkbox = (props) => (
    <Stack className="ory-checkbox">
        <Field name={props.name}>
            {({ field, form }) => (
                <ChakraCheckbox
                    mb={
                        props.help
                            ? `5px`
                            : {
                                  base: `15px`,
                                  md: `30px`,
                              }
                    }
                    aria-label={props.label}
                    size={`md`}
                    variantColor="brandBlue"
                    borderColor={`brand.darkGrey`}
                    checked={field.value}
                    onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                    }
                    isDisabled={form.isSubmitting || props.disabled}
                    iconColor="brand.darkGrey"
                    {...props}
                >
                    {props?.label?.length > 0 && (
                        <Text fontSize="14px" my="0 !important">
                            {props.label}
                            {props.required && (
                                <Text as="sup" color="brand.orange">
                                    *
                                </Text>
                            )}
                        </Text>
                    )}
                </ChakraCheckbox>
            )}
        </Field>

        {props?.help?.length > 0 && (
            <Text color="brand.black" fontSize="12px !important" mb="15px">
                {props.help}
            </Text>
        )}
    </Stack>
);
