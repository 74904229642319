import React from 'react'
import { Field } from 'formik'

import { Input as ChakraInput, Text } from '@chakra-ui/core'

export const FormInput = (props) => (
    <>
        {props?.label?.length > 0 && props?.type !== 'hidden' && (
            <>
                <Text
                    mb="10px !important"
                    fontSize="12px !important"
                    fontWeight="400 !important"
                    color="brand.black !important"
                    opacity={0.75}
                >
                    {props.label}
                    {props.required && (
                        <Text as="sup" color="brand.black">
                            *
                        </Text>
                    )}
                </Text>
            </>
        )}

        <Field name={props.name}>
            {({ field, form }) => (
                <ChakraInput
                    color={field.value?.length > 0 ? 'brand.black' : 'brand.darkGrey'}
                    _hover={{ borderColor: `brand.darkGrey` }}
                    fontSize={`14px`}
                    _placeholder={{ color: 'brand.black', opacity: 0.5 }}
                    borderRadius={0}
                    backgroundColor="brand.white"
                    _focus={{
                        borderColor: `brand.blue`,
                    }}
                    mb={
                        props.help
                            ? `5px`
                            : {
                                  base: `15px`,
                                  md: `30px`,
                              }
                    }
                    aria-label={props.label}
                    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                    isDisabled={form.isSubmitting || props.disabled}
                    borderColor={field.value?.length > 0 ? 'brand.blue' : 'brand.grey'}
                    {...props}
                />
            )}
        </Field>

        {props?.help?.length > 0 && props?.type !== 'hidden' && (
            <Text color="brand.black" fontSize="12px !important" mb="15px">
                {props.help}
            </Text>
        )}
    </>
)
