import React from "react";
import { Field } from "formik";
import { Radio as ChakraRadio, RadioGroup, Text } from "@chakra-ui/core";
import styled from "@emotion/styled";
import { EditorTheme as Theme } from "../theme";

const RadioStyles = styled.div`
    input[type="radio"] {
        + div[type="radio"] {
            border-width: 1px;
        }

        &:checked {
            + div[type="radio"] {
                border-color: ${Theme.colors.brand.blue};

                > span {
                    background-color: ${Theme.colors.brand.blue};
                    height: 70%;
                    width: 70%;
                }
            }
        }
    }
`;

export const Radio = (props) => {
    return (
        <>
            {props?.label?.length > 0 && (
                <Text
                    mb="10px !important"
                    fontSize="12px !important"
                    fontWeight="400 !important"
                    color="brand.black !important"
                    opacity={0.75}
                >
                    {props.label}
                    {props.required && (
                        <Text as="sup" color="brand.black">
                            *
                        </Text>
                    )}
                </Text>
            )}
            {props?.options?.length > 0 && (
                <Field name={props.name}>
                    {({ field, form }) => (
                        <RadioStyles>
                            <RadioGroup
                                onChange={(e) =>
                                    form.setFieldValue(
                                        field.name,
                                        e.target.value
                                    )
                                }
                                value={field.value || props.defaultValue}
                                mb={props?.help?.length > 0 ? "5px" : "15px"}
                            >
                                {props.options.map((option, idx) => (
                                    <ChakraRadio
                                        key={`${field.name}-${idx}`}
                                        mb={"5px"}
                                        aria-label={option.label}
                                        size={`md`}
                                        variantColor="brandBlue"
                                        borderColor="brand.darkGrey"
                                        isDisabled={
                                            form.isSubmitting || props.disabled
                                        }
                                        value={option.value}
                                        color="brand.darkGreen"
                                    >
                                        {option?.label?.length > 0 && (
                                            <Text
                                                fontSize="14px"
                                                my="0"
                                                color="brand.black"
                                                fontWeight={600}
                                                mb="0 !important"
                                            >
                                                {option.label}
                                            </Text>
                                        )}
                                    </ChakraRadio>
                                ))}
                            </RadioGroup>
                        </RadioStyles>
                    )}
                </Field>
            )}
            {props?.help?.length > 0 && (
                <Text color="brand.black" fontSize="12px !important" mb="15px">
                    {props.help}
                </Text>
            )}
        </>
    );
};
