export const primary = {
    accentColor: 'rgba(57, 83, 83, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '45px',
        borderRadius: '9999px',
        textTransform: 'uppercase',
        fontWeight: 600,
        minW: '140px',
        fontSize: '14px',
        fontFamily: 'heading',
        pt: '2px',
    },
    solidProps: {
        color: 'white',
        _hover: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(57, 83, 83, 0.75)' },
        _active: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(57, 83, 83, 1)' },
    },
    outlineProps: {
        bg: 'transparent',
        color: 'rgba(57, 83, 83, 1)',
        borderColor: 'rgba(57, 83, 83, 1)',
        _hover: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(57, 83, 83, 1)' },
        _focus: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(57, 83, 83, 1)' },
        _active: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(57, 83, 83, 1)' },
    },
}

export const secondary = {
    accentColor: 'rgba(34, 31, 32, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '45px',
        borderRadius: '9999px',
        textTransform: 'uppercase',
        fontWeight: 600,
        minW: '140px',
        fontSize: '14px',
        fontFamily: 'heading',
        pt: '2px',
    },
    solidProps: {
        color: 'white',
        _hover: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(34, 31, 32, 0.75)' },
        _active: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(34, 31, 32, 1)' },
    },
    outlineProps: {
        bg: 'transparent',
        color: 'rgba(34, 31, 32, 1)',
        _hover: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(34, 31, 32, 1)' },
        _focus: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(34, 31, 32, 1)' },
        _active: { color: 'rgba(255, 255, 255, 1)', bg: 'rgba(34, 31, 32, 1)' },
    },
}
