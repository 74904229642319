import React from "react";
import { Link, Text, Stack } from "@chakra-ui/core";
import isObjectLike from "lodash/isObjectLike";
import sortBy from "lodash/sortBy";

export const ItemCard = (props) => {
    const {
        incTax,
        imagePresets,
        imageProps,
        link,
        loggedIn,
        product,
        ItemCardButtons,
        productNameProps,
        productComparison,
        salesMessageProps,
        separatorProps,
        stockMessageProps,
        ...restProps
    } = props;

    const productImages = product.resources?.filter((r) => r.type === "image");
    const firstImage = productImages
        ? sortBy(productImages, ["displayOrder"])[0]
        : null;

    return (
        <Stack {...restProps} data-testid={`product-card`}>
            <Link
                href={`/products/${product.slug}`}
                className="product-card__link"
                {...(typeof link === "function" && { onClick: link })}
                {...(typeof link === "string" && { href: link })}
            >
                <Stack
                    {...(isObjectLike(product.attributes) && {
                        title: product.attributes.product_name,
                    })}
                    position="relative"
                    style={{
                        aspectRatio: "1",
                    }}
                    backgroundImage={`url(${firstImage?.location})`}
                    backgroundColor="brand.white"
                    backgroundSize="contain"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    w="100%"
                    data-testid={`product-card__media`}
                ></Stack>
                <Text
                    fontSize="16px"
                    lineHeight="24px"
                    color="brand.black"
                    my="10px"
                    className="product-card__name"
                    {...productNameProps}
                    data-testid={`product-card__name`}
                >
                    {isObjectLike(product.attributes) &&
                        product.attributes.product_name}
                </Text>
            </Link>
        </Stack>
    );
};

ItemCard.defaultProps = {
    incTax: true,
    loggedIn: true,
    link: function () {
        console.log("clicking product link");
    },
};
