import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../CustomerGroupsForm";
import { formatDataForApi } from "../util";

const CustomerGroupsAdd = ({ CustomerGroupsForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState();

    const schema = yup.object().shape({
        customerGroup: getSchemaFromResource(
            getResource("customer-groups")
        ).shape({
            name: yup.string().required(),
        }),
    });

    const onSubmit = async (data, actions) => {
        try {
            const response = await client(CREATE, "customer-groups", {
                data: formatDataForApi(data),
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.map((error) => {
                    return actions.setFieldError(
                        error.propertyPath,
                        error.message
                    );
                });
            } else {
                setRedirect(response.data.id);
                toast.success("Customer Group successfully added!");
                actions.setSubmitting(false);
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.map((error) => {
                    return actions.setFieldError(
                        error.propertyPath,
                        error.message
                    );
                });
            }
            actions.setSubmitting(false);
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>Customer Groups | Add | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Add Customer Group">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            to="/customer-groups"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Customer Groups
                        </Link>
                    </div>
                </Heading>
                <CustomerGroupsForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                />
            </div>
        </>
    );
};

export default CustomerGroupsAdd;
