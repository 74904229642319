import React from "react";
import { Field } from "formik";

import { Textarea as ChakraTextarea, Text } from "@chakra-ui/core";

export const Textarea = (props) => (
    <>
        {props?.label?.length > 0 && (
            <Text
                mb="10px !important"
                fontSize="12px !important"
                fontWeight="400 !important"
                color="brand.black !important"
                opacity={0.75}
            >
                {props.label}
                {props.required && (
                    <Text as="sup" color="brand.orange">
                        *
                    </Text>
                )}
            </Text>
        )}
        <Field name={props.name}>
            {({ field, form }) => (
                <ChakraTextarea
                    color={
                        field.value?.length > 0
                            ? "brand.black"
                            : "brand.darkGrey"
                    }
                    borderColor={
                        field.value?.length > 0
                            ? "brand.blue"
                            : "brand.darkGrey"
                    }
                    backgroundColor="brand.white"
                    fontSize={`14px`}
                    _placeholder={{ color: "brand.darkGrey" }}
                    _focus={{
                        borderColor: `brand.blue`,
                    }}
                    mb={
                        props.help
                            ? `5px`
                            : {
                                  base: `15px`,
                                  md: `30px`,
                              }
                    }
                    borderRadius={0}
                    onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                    }
                    isDisabled={form.isSubmitting || props.disabled}
                    {...props}
                />
            )}
        </Field>
        {props?.help?.length > 0 && (
            <Text color="brand.black" fontSize="12px !important" mb="15px">
                {props.help}
            </Text>
        )}
    </>
);
