import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";

import {
    Form,
    Group,
    Input,
    Checkbox,
    Tags,
    Modal,
    Select,
    FormActions,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";

import { DELETE, GET_LIST, useClient } from "@peracto/client";
import { useSettings } from "@peracto/peracto-hooks";
import { useConfig } from "@peracto/peracto-config";

import AddressForm from "./AddressForm";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const DEFAULT_ADDRESS_LIMIT_TYPES = ["billing", "delivery"];
const DEFAULT_PAYMENT_TYPES = ["account", "card"];
const DEFAULT_PERMITTED_RESOURCES = ["document", "image", "video"];

const CustomerGroupsForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onSaveAddress,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [redirect, setRedirect] = useState();
    const [customerGroupTypes, setCustomerGroupTypes] = useState([]);
    const [visibleFields, setVisibleFields] = useState({});

    const { values: p_values } = useSettings();
    const PERMITTED_RESOURCES =
        p_values?.resource_types || DEFAULT_PERMITTED_RESOURCES;
    const emailSuffixes = p_values?.emailSuffixes || [];

    const { client } = useClient();

    const config = useConfig();
    const { customerGroups } = config.get("features", {});

    const onDelete = async () => {
        try {
            await client(DELETE, "customer-groups", {
                id: values.id,
            });

            toast.success("Customer Group deleted successfully!");
            setRedirect("/customer-groups");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const defaultAddressLimitValues = {};

    DEFAULT_ADDRESS_LIMIT_TYPES.forEach(
        (type) => (defaultAddressLimitValues[type] = "")
    );

    const defaultValues = {
        name: "",
        emailSuffixes: emailSuffixes,
        form_permissions: {
            address_limits: defaultAddressLimitValues,
            permitted_resources: [],
            allowed_payment_types: [],
            percentage_discount: 0,
            create_wishlists: false,
            login_disabled: false,
            ordering_disabled: false,
            show_product_prices: false,
        },
    };

    if (customerGroups?.accessAllQuotes) {
        defaultValues.form_permissions.access_all_quotes = false;
    }

    useEffect(() => {
        async function getCustomerGroupTypes() {
            const { data } = await client(GET_LIST, "customer-group-types", {
                id: "customer-group-types",
                filter: {
                    itemsPerPage: 1000,
                },
            });

            const types = data.map((type) => {
                return {
                    label: type.label,
                    value: type["@id"],
                };
            });

            setCustomerGroupTypes(types);
        }

        getCustomerGroupTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            {mode === MODE_EDIT && (
                <FormActions>
                    <button
                        className="btn btn-link text-danger p-0"
                        onClick={() => {
                            setShowDialog(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Delete Customer Group
                    </button>
                </FormActions>
            )}

            <Form
                autoComplete="off"
                {...props}
                values={{
                    customerGroup: {
                        ...defaultValues,
                        ...values?.customerGroup,
                        type: values?.customerGroup?.type?.["@id"] || null,
                    },
                    addresses: values?.addresses || [],
                }}
            >
                <Group key="core" id="core" name="Core">
                    <Input
                        name="customerGroup.name"
                        label="Customer Group Name"
                        testId="customer-group-name"
                        required
                    />
                    
                    <Input
                        name="customerGroup.emailSuffixes"
                        label="Customer Group Email Suffixes"
                        help="Used to determine if a customer is part of this group. Separate multiple suffixes with a comma. For example: '9xb.com,test.com'"
                        testId="customer-group-emailSuffixes"
                    />

                    <Select
                        name="customerGroup.type"
                        label="Customer Group Type"
                        testId="customer-group-type"
                        options={customerGroupTypes}
                        allowClear={true}
                    />
                </Group>

                <Group
                    key="form_permissions"
                    id="form_permissions"
                    name="Permissions"
                >
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <label>Address Limits</label>
                        </div>
                        <div className="col-12 col-md-10">
                            <div className="row">
                                {DEFAULT_ADDRESS_LIMIT_TYPES.map(
                                    (addressLimit) => (
                                        <div
                                            className="col-12 col-md-3"
                                            key={addressLimit}
                                        >
                                            <Input
                                                type="number"
                                                label={startCase(addressLimit)}
                                                name={`customerGroup.form_permissions.address_limits.${addressLimit}`}
                                                id={`address-limit_${addressLimit}`}
                                                testId={`address-limit_${addressLimit}`}
                                                required
                                                min={0}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    <Tags
                        name={`customerGroup.form_permissions.address_prevent_add`}
                        label="Address Prevent Add"
                        options={DEFAULT_ADDRESS_LIMIT_TYPES.map((type) => ({
                            label: startCase(type),
                            value: type,
                        }))}
                        placeholder="Address Prevent Add"
                    />

                    <Tags
                        name={`customerGroup.form_permissions.address_prevent_edit`}
                        label="Address Prevent Edit"
                        options={DEFAULT_ADDRESS_LIMIT_TYPES.map((type) => ({
                            label: startCase(type),
                            value: type,
                        }))}
                        placeholder="Address Prevent Edit"
                    />

                    <Tags
                        name={`customerGroup.form_permissions.address_prevent_delete`}
                        label="Address Prevent Delete"
                        options={DEFAULT_ADDRESS_LIMIT_TYPES.map((type) => ({
                            label: startCase(type),
                            value: type,
                        }))}
                        placeholder="Address Prevent Delete"
                    />

                    <Tags
                        name={`customerGroup.form_permissions.allowed_payment_types`}
                        label="Allowed Payment Types"
                        options={DEFAULT_PAYMENT_TYPES.map((type) => ({
                            label: startCase(type),
                            value: type,
                        }))}
                        required
                        placeholder="Allowed Payment Types"
                    />

                    <Input
                        type="number"
                        label="Percentage Discount"
                        name={`customerGroup.form_permissions.percentage_discount`}
                        testId={`percentage-discount`}
                        required
                        min={0}
                        max={100}
                    />

                    <Tags
                        name={`customerGroup.form_permissions.permitted_resources`}
                        label="Permitted Resources"
                        options={PERMITTED_RESOURCES.map((type) => ({
                            label: startCase(type),
                            value: type,
                        }))}
                        required
                        placeholder="Permitted Resources"
                    />

                    <Checkbox
                        name={`customerGroup.form_permissions.create_wishlists`}
                        label="Create Wishlists"
                    />

                    <Checkbox
                        name={`customerGroup.form_permissions.login_disabled`}
                        label="Login Disabled"
                    />

                    <Checkbox
                        name={`customerGroup.form_permissions.show_product_prices`}
                        label="Show Product Prices"
                    />

                    <Checkbox
                        name={`customerGroup.form_permissions.ordering_disabled`}
                        label="Ordering Disabled"
                    />

                    <Checkbox
                        name={`customerGroup.form_permissions.access_all_quotes`}
                        label="Access All Quotes"
                    />
                </Group>
            </Form>

            {mode === MODE_EDIT && (
                <>
                    <AddressForm
                        values={values}
                        visibleFields={visibleFields}
                        setVisibleFields={setVisibleFields}
                        setFormData={setFormData}
                        onSaveAddress={onSaveAddress}
                        {...props}
                    />

                    <Modal
                        isVisible={showDialog}
                        title="Delete Customer Group"
                        close={() => setShowDialog(false)}
                        buttons={[
                            {
                                type: "btn-outline-secondary",
                                text: "Close",
                                action: () => setShowDialog(false),
                            },
                            {
                                type: "btn-danger",
                                text: "Delete Customer Group",
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="mb-4 d-block"
                        />
                        Are you sure you would like to permanently delete this
                        customer group? Deleted customer groups cannot be
                        recovered.
                    </Modal>
                </>
            )}
        </>
    );
};

CustomerGroupsForm.displayName = "CustomerGroupsForm";

CustomerGroupsForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CustomerGroupsForm;
