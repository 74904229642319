import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
    GET_ONE,
    GET_MANY,
    GET_LIST,
    UPDATE,
    CREATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";

import { MODE_EDIT } from "../CustomerGroupsForm";
import { formatDataForForm, formatDataForApi } from "../util";

const CustomerGroupsEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Customer Group">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        to="/customer-groups"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Customer Groups
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const CustomerGroupsEdit = ({ CustomerGroupsForm, match: { params } }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();
    const [customerGroupData, setCustomerGroupData] = useState();
    const [countryData, setCountryData] = useState();

    const fetchCustomerGroup = async () => {
        try {
            const { data, response } = await client(
                GET_ONE,
                "customer-groups",
                {
                    id: `/customer-groups/${params.id}`,
                }
            );

            const { data: addresses } = await client(
                GET_LIST,
                "customer-group-addresses",
                {
                    id: `customer-group-addresses`,
                    filter: {
                        customerGroup: `/customer-groups/${params.id}`,
                        itemsPerPage: 1000,
                    },
                }
            );

            if (response.status === 404) {
                setRedirect("/customer-groups");
                setLoading(false);
                return;
            }

            const customerGroup = formatDataForForm(data);

            setCustomerGroupData({ customerGroup, addresses });
            fetchCountries();

            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/customer-groups");
            setLoading(false);
        }
    };

    const fetchCountries = async () => {
        try {
            const { data: countryData, response: countryResponse } =
                await client(GET_MANY, "countries", {
                    id: "countries",
                });

            setCountryData(countryData);

            setLoading(false);

            if (countryResponse.status === 404) {
                setRedirect("/customer-groups");
            }
        } catch (e) {
            console.error(e);
            setRedirect("/users");
        }
    };

    useEffect(() => {
        fetchCustomerGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = yup.object().shape({
        customerGroup: getSchemaFromResource(
            getResource("customer-groups")
        ).shape({
            name: yup.string().required(),
        }),
    });

    const onSubmit = async (data, actions) => {
        const formattedData = formatDataForApi(data, true);

        try {
            const response = await client(UPDATE, "customer-groups", {
                id: `/customer-groups/${params.id}`,
                data: formattedData,
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.map((error) => {
                    return actions.setFieldError(
                        error.propertyPath,
                        error.message
                    );
                });
            } else {
                actions.setSubmitting(false);
                toast.success("Customer Group successfully updated!");
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.map((error) => {
                    return actions.setFieldError(
                        error.propertyPath,
                        error.message
                    );
                });
            }
            actions.setSubmitting(false);
        }
    };

    const onSaveAddress = async (address) => {
        const hasId = address["@id"];

        try {
            await client(hasId ? UPDATE : CREATE, "customer-group-addresses", {
                id: hasId ? address["@id"] : null,
                data: {
                    ...address,
                    customerGroup: `/customer-groups/${params.id}`,
                },
            });

            const { data: addressData } = await client(GET_MANY, "addresses", {
                id: `customer-group-addresses?customerGroup=${params.id}`,
            });

            setCustomerGroupData({
                ...customerGroupData,
                addresses: addressData,
            });

            toast.success("Address saved successfully!");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    if (loading) {
        return (
            <CustomerGroupsEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </CustomerGroupsEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/customer-groups"
                message="The customer group you're looking for could not be found"
                buttonLabel="Go to Customer Groups"
            />
        );
    }

    return (
        <CustomerGroupsEditContainer>
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {customerGroupData.name || "Customer Group"} | Edit
                            | Peracto
                        </title>
                    </Helmet>
                    <CustomerGroupsForm
                        values={customerGroupData}
                        countries={countryData}
                        onSubmit={onSubmit}
                        onSaveAddress={onSaveAddress}
                        schema={schema}
                        mode={MODE_EDIT}
                    />
                </>
            )}
        </CustomerGroupsEditContainer>
    );
};

export default CustomerGroupsEdit;
