import React from "react";
import ReactSelect, { components } from "react-select";
import { Text, Stack } from "@chakra-ui/core";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { EditorTheme as Theme } from "../theme";

export const Select = (props) => {
    const { name, options, label, required } = props;
    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    {label?.length > 0 && (
                        <Text
                            mb="10px !important"
                            fontSize="12px !important"
                            fontWeight="400 !important"
                            color="brand.black !important"
                            opacity={0.75}
                        >
                            {label}
                            {required && (
                                <Text as="sup" color="brand.black">
                                    *
                                </Text>
                            )}
                        </Text>
                    )}

                    <Stack mb="15px">
                        <ReactSelect
                            onChange={(item) => {
                                form.setFieldValue(field.name, item.value);
                            }}
                            className="react-select"
                            {...(field.value?.length > 0 && {
                                value: options.filter(
                                    (option) => option.label === field.value
                                ),
                            })}
                            options={options}
                            styles={{
                                control: (provided, { isFocused }) => ({
                                    ...provided,
                                    minHeight: '45px',
                                    padding: '0 5px',
                                    backgroundColor: Theme.colors.brand.white,
                                    borderColor: Theme.colors.brand.grey,
                                    boxShadow: isFocused ? 'none' : 'none',
                                    fontSize: '14px',
                                    fontFamily: Theme.fonts.body,
                                    borderRadius: '0px',
                                    ':hover': {
                                        borderColor: Theme.colors.brand.grey,
                                    },
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: Theme.colors.brand.black,
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    margin: '0',
                                    left: '0',
                                    padding: '0px',
                                    color: Theme.colors.brand.black,
                                    backgroundColor: Theme.colors.brand.white,
                                    border: '1px solid',
                                    borderColor: Theme.colors.brand.grey,
                                    borderRadius: '0px',
                                }),
                                option: (
                                    provided,
                                    { isDisabled, isFocused }
                                ) => ({
                                    ...provided,
                                    padding: '10px 15px',
                                    fontSize: '14px',
                                    color: Theme.colors.brand.black,
                                    opacity: isDisabled ? 0.7 : 1,
                                    backgroundColor: isFocused
                                        ? Theme.colors.brand.darkGreen25
                                        : Theme.colors.brand.white,
                                    '&:active': {
                                        backgroundColor: Theme.colors.brand.white,
                                    },
                                }),
                            }}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                            }}
                            data-testid="select-input"
                        />
                    </Stack>
                </>
            )}
        </Field>
    );
};

const CaretDownIcon = () => {
    return (
        <Text color={`brand.darkGreen`}>
            <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                    height: `14px`,
                    width: `14px`,
                    display: `flex`,
                    color: Theme.colors.brand.darkGreen,
                }}
            />
        </Text>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};
